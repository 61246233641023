#{$app}-{
    &userDetails{
        &__{
            &wrapper{
                border-radius:25px;
            }
            &image{
                width:60px;
                height:60px;
                border-radius:50%;
                object-fit: cover;
                object-position: center;
            }
            &icon{
                width:25px;
                height:25px;
            }
        }
    }
}