#{$app}- {
    &primary {
        &__ {
            &button {
                color: var(--color-app);
                border: 1px solid #FFFFFF;
                border-radius: 6px;
                padding: 12px 19px;
                &:hover {
                    color: var(--color-app);
                }
            }
        }
    }
    &successBtn{
        background-color: var(--color-lightgreen);
        border-radius:8px;
        padding:9px 20px 9px 20px;
        border:1px solid var(--color-lightgreen);
        &__{
            &icon{
                width:14px;
                height:14px;
            }
        }
        &--{
            &transparent{
                color: var(--color-darkred);
                background-color: transparent;
                border-color: 1px solid var(--color-darkred);
            }
        }

    }
    &removeItem {
        &__ {
            &icon {
                &:hover {
                    color: #FF5353;
                }
            }
        }
    }
    &paginationBtn{
        padding-right: 9px;
        padding-left: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        &--{
            &next{
                padding-right:7px;
                padding-left:9px;
            }
        }
    }
}

