* {
  font-family: "Montserrat", sans-serif;
}
body {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.no-scrollbar{
  overflow-y: hidden!important; /* Hide vertical scrollbar */
  overflow-x: hidden!important;/* Hide horizontal scrollbar */
}
.apexcharts-canvas {
  font-family: "Montserrat", sans-serif;
}
div {
  box-sizing: border-box;
}

::-webkit-scrollbar {
	background-color: #F5F5F5;
  width: 8px;   // for vertical scroll bar
  height: 8px;  // for horizontal scroll bar
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color:  #F5F5F5;
	border-radius: 10px;
  margin-right: 10px;
  // border: 2px solid #171717;
  
}


::-webkit-scrollbar-thumb {
  border-radius: 10px;
	// background: transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box;
  background-color: #383737;
}


::-webkit-scrollbar-thumb:hover {
  background: #868686;
}

// resets drag and drop
.ewqTBN {
  border: 1px solid #d2d6da !important;
  border-radius: 5px;
  height: 180px !important;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
input::-webkit-input-placeholder {
  /* Edge */
  font-family: var(--body-font) !important;
}

input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: var(--body-font) !important;
}

input::placeholder {
  font-family: var(--body-font) !important;
}

// custom tabs
.po-custom-tabs {
  .nav-link {
    color: var(--color-app);
    font-family: var(--body-font);
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--color-app);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    color: var(--color-ternary);

  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: #cba46100;
  }
}
// .po-card{
//   input[type=checkbox] {
//     position: relative;
//     border: 2px solid #000;
//     border-radius: 2px;
//     background: none;
//     cursor: pointer;
//     line-height: 0;
//     margin: 0 .6em 0 0;
//     outline: 0;
//     padding: 0 !important;
//     vertical-align: text-top;
//     height: 20px;
//     width: 20px;
//     -webkit-appearance: none;
//     opacity: .5;
//   }

//   input[type=checkbox]:hover {
//     opacity: 1;
//   }

//   input[type=checkbox]:checked {
//     background-color: #000;
//     opacity: 1;
//   }

//   input[type=checkbox]:before {
//     content: '';
//     position: absolute;
//     right: 50%;
//     top: 50%;
//     width: 4px;
//     height: 10px;
//     border: solid #FFF;
//     border-width: 0 2px 2px 0;
//     margin: -1px -1px 0 -1px;
//     transform: rotate(45deg) translate(-50%, -50%);
//     z-index: 2;
//   }
// }
#dropdown-basic {
  &::after{
    content: unset!important;
  }
}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #F6F9FC;
}
.tooltip-inner {
  max-width: 1000px !important; //define whatever width you want
  text-align: start;
}
// table {
//   min-height: 200px;
// }
// .h-90{
//   height: 82%;
// }
tbody > tr:last-child > td {
  border-bottom: 0;
}
.page-link.active, .active > .page-link{
  background-color:#171717;
  border-color:#171717;
  &:hover{
    color:#ffffff;
  }
}
.page-link{
  // color:#DBA611;
  color: #374666;
  border: 1px solid #171717;
  &:hover{
    color:#DBA611;
  }
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: rgba(219, 165, 17, 0.1) 0px 4px 16px, rgba(219, 165, 17, 0.05) 0px 8px 32px;
}
.po-custom-tabs .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #5b1f6e;
  background-color: #ffffff;
  border-bottom:3px solid #5b1f6e;
  border-radius: 0;
}

// calender style override
.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #5b1f6e;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #5b1f6e;
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #5b1f6e;
}
.rest-space{
  height:calc(100vh - 220px);
}
.object-contain-wrapper{
  height:350px;
  width:450px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.users-tabs{
  &.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #5b1f6e;
    background-color: #ffffff;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 3px solid #5b1f6e;
}
&.nav-tabs .nav-link {
    color: #000;
}
}

// radio resets
.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

/* Style the radio button */
.radio-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #5b1f6e;
  margin-right: 10px;
}

/* Style the radio button when selected */
.radio-container input[type="radio"]:checked {
  background-color: #5b1f6e;
}
.mw-15{
  min-width: 150px;
  z-index: 5;
  position: relative;
}