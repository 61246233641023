#{$app}- {
    &updateEvent {
        &__ {
            &inputPhoto {
                height: 200px !important;
                width: 350px !important;
                background-position: center;
                // background-color: black;
                @include respond-below(lg) {
                    width: 100% !important;
                    max-width: 280px;
                    height: 150px !important;
                }
            }
            &clubLists {
                padding: 10px 0;
                background: black;
                color: white;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                max-height: 200px;
                overflow-y: scroll;
                cursor: pointer;
                li {
                    transition: all 0.1s ease;
                    &:hover {
                        background-color: var(--color-skyblue) !important;
                        color: black;
                    }
                }
            }
            &inputList {
               background-color: #F9F9F9;
               border-radius: 6px; 
               height: 146px;
               overflow: scroll;
               @include respond-below(lg) {
                    height: 98px;
               }
               &- {
                &item {
                    margin-top: 0 !important;
                }
                &link {
                    color: #3448FF;
                    &:hover {
                        color: #3448FF;    
                    }
                }
               }
            }
        }
    }
}