#{$app}- {
    &sidebar {
        &::-webkit-scrollbar {
            display: none;
          }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        width: 200px;
        flex-shrink: 0;
        height: calc(100vh - 40px);
        background: var(--color-app) 0% 0% no-repeat padding-box;

        border-radius: 20px;
        @include respond-above(lg) {
            width: 250px;
        }
        @include respond-above(xl) {
            width: 300px;
        }
        &__ {
            &logo {
                width: 70px;
                @include respond-above(lg) {
                    width: 90px;
                }
            }
            &link {
                position: relative;
                color: var(--color-primary);
                &:hover {
                    color: var(--color-primary);
                }
                &-- {
                    &active {
                        background: var(--color-primary);
                        color: var(--color-app);
                        border-radius: 9px;
                        &:hover {
                            color: var(--color-app);
                        }
                        // img {
                        //     display: none;
                        // }
                        #{$app}-sidebar__icon--active {
                            display: block;
                        }
                    }
                }
            }
            &icon {
                &-- {
                    &active {
                        display: none;
                    }
                }
            }
            &sub {
                &- {
                    &lists {
                        max-height: 0px;
                        width: 100%;
                        overflow: hidden;
                        transition: all 3s ease-in-out;
                        padding-left: 24px;
                    }
                    &list {
                        color: var(--color-primary);
                    }
                }
            }
            &bottom {
                bottom: 42px;
                left: 25px;
                color: #F9F9F9;
            }
        }
    }
}

.showList {
    max-height: 100px;
    animation: showSubListItems 1s ease-in-out;
    // -webkit-transition: max-height, 1s ease-in-out;
    // transition: max-height, 3s ease-in-out;
}
.hideList {
    max-height: 0px;
    animation: hideSubListItems 1s ease-in-out;
}

@keyframes showSubListItems {
    0% {
    max-height: 0px;
    }
    100% {
    max-height: 100px;
    }
}
@keyframes hideSubListItems {
    0% {
        
    max-height: 100px;
    }
    100% {
    max-height: 0px;
    }
}
#{$app}-{
    &dropdown{
        border-radius: 8px;
        border-style:  solid;
        // border-width: 1.5px;
        border-color:  #171717;
        &__{
            &activeLink{
                &:hover{
                    color: #F7CE73;
                }
                &:visited{
                    background-color: #F9F9F9;
                }
            }
        }
        &--{
            &solid{
                // background-color: #fff;  
                // border-color:  #5b1f6e;
                border-radius: 10px;
                transition: border-color 0.9s;
                // background-color: #5b1f6e;
                background-color: #782762;
            }
        }
    }
}