#{$app}-{
    &input{
        padding:13px 15px 15px 15px;
        border-radius:5px;
        border:none;
       
        &::placeholder{
            font-size:14px;
            color:#B1B8BF;
            font-family:var(--ff-montserrat);
            font-weight:600;
        }
        // &:focus{
        //     outline-color: var(--color-ternary) !important;
        //     border: 0;
        // }
        &--{
            &greyBorder{
                border:1px solid #D2D6DA;
                &::placeholder{
                    color:#B1B8BF;
                    font-size:14px;
                }
            }
        }
        &--{
            &darkGreyBorder{
                border: 1px solid #727272;
                &::placeholder{
                    color:#9A9A9A;
                    font-size:14px;
                }
            }
        }
        &__{

            &fileText{
                border: 1px solid #D2D6DA;
                width:100%;
                height:180px;
                border-radius:5px;
            }
            &change {
                right: 15px;
                @include respond-below(xl) {
                    right: 10px;
                }
                &- {
                    &icon {
                        @include respond-above(lg) {
                            font-size: 18px;
                        }
                    }
                    &input {
                        &[type=number]::-webkit-inner-spin-button,
                        &[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        }
                    }
                }
            }
            &table {
                min-width: 116px;
            }
        }
    }
    &checkbox{
        appearance: none;
        -webkit-appearance:none;
        height:15px;
        width:15px;
        background-color: #E8E9E9;
        border-radius:5px;
        display:flex;
        align-items:center;
        justify-content: center;
        &:hover{
            background-color: #f5e3bd;
        }
        &:after{
            content:'';
            display:none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:checked{
            background-color:var(--color-ternary);
        }
        &:checked:after{
            display:block;
        }
    }
}
.fill-gray svg{
  fill:#D2D6DA!important;
}
#{$app}-{
  &edit{
      &-{
          &input{
             display: block;
             width: 100%;
             border :1px solid #D2D6DA;
             border-radius: 5px; 
            padding: 10px;
            &::placeholder{
                font-weight:400;
                color:rgb(208, 207, 207);
            }
            //  &:focus{
            // //   border: 1px solid var(--color-ternary) !important;
            //     outline-color: var(--color-ternary) !important;
            //     border: 0;
            //  }
             
            //  &2 {
            //     display: block;
            //     width: 100%;
            //     border :1px solid #D2D6DA;
            //     border-radius: 5px; 
            //     padding: 10px;
            //     outline: none;
            //  }
          }
         
      }
  }
}

input#file-upload-button{
    display:none;
}

.PhoneInputInput{
    &::placeholder{
        font-weight:400 !important;
        color:rgb(208, 207, 207) !important;
    }
}