#{$app}-{
  &card{
    box-shadow: 0px 3px 20px #00000014; 
  }
}

#{$app}-{
  &adsCard{
    box-shadow: 0px 3px 6px #00000029;
    &__{
      &thumbnail{
        height: 100px;
        width:100%;
      }
    }
  }
}