#{$app}-{
    &requestDetails{
        &-{
            &table{
                border-bottom: 1px solid rgba(112, 112, 112, 22%);
            }
        }
        &__{
            &statusBtn{
                border-radius:17px;
                border: 1px solid #FFEBE8;
                background-color: #FFEBE8;
                color:#B1630A;
                font-size:14px;
                padding: 6px 34px 8px 34px;
            }
        }
    }
}