#{$app}- {
    &modalContent {
        &-- {
            &radius {
                border-radius: 25px;
            }
        }
    }
    &primaryModal {
        &__ {
            &header {
                background-color: var(--color-primary);
            }
        }
    }
    &filter {
        max-height: min(450px, 60vh);
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        &__btn {
            &- {
                &clear {
                    transition: all;
                    transition-duration: .3s;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                    &:hover {
                        color: var(--color-red) !important;
                    }
                }
                &apply {
                    transition: all;
                    transition-duration: .3s;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        &__ {
            &itemsList {
                width: 70%;
                max-height: 250px;
                overflow-y: auto;
                transition: all 3s ease;
                &- {
                    &title {
                        width: 30%;
                    }
                    &item {
                        border-bottom: 1px dotted var(--color-gray2);
                        &:first-child {
                            padding-top: 0 !important;
                        }
                        &:last-child {
                            padding-bottom: 0 !important;
                            border-bottom: 0 !important;
                        }
                        input {
                            width: 0%;
                        }
                    }
                }
            }
        }
    }
}

.po-supportModal .PhoneInputCountrySelectArrow{
    color: #ffffff !important;
    opacit: 1;
}