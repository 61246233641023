#{$app}- {
    &eventDetails {
        &__ {
            &wrapper {
                width: 25%;
                min-width: 300px;
                img {
                    width: 100%;
                    height: 100%; 
                    object-fit: contain;
                    object-position: center;
                }
            }
            &key {
                width: 100px;
                word-break: break-all;
            }
            &value {
                width: calc(100% - 100px);
            }
        }
    }
}