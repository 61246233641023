@import './header', './sidebar';

#{$app}- {
    &layout {
        // background: linear-gradient(#DEDEDE 300px, #F0F2F4 300px);
        background-color: #F0F2F4;
        height: 100vh;
        box-sizing: border-box;
    }
    &section {
        &__ {
            &content {
                height: 100%;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    margin: 0 10px;
                }
            }
        }
    }
    &main {
        @include respond-above(xl) {
            column-gap: 32px !important;
        }
    }
}