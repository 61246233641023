:root {
  // -----------------  Font Family  ------------------------//

  --body-font: "Montserrat", sans-serif;
  --head-font: "Montserrat", sans-serif;
  // -----------------  Font Sizes  ------------------------//

  --text-base: 0.25rem;
  --text-xxs: 10px;
  --text-xs: calc(var(--text-base) * 3);
  --text-sm: 14px;
  --text-15: 15px;
  --text-md: calc(var(--text-base) * 4);
  --text-lg: 18px;
  --text-xl: calc(var(--text-base) * 5);
  --text-2xl: calc(var(--text-base) * 6);
  --text-3xl: calc(var(--text-base) * 7);
  --text-4xl: calc(var(--text-base) * 8);
  --text-5xl: calc(var(--text-base) * 9);
  --text-section: calc(var(--text-base) * 10);
  --text-39: calc(var(--text-base) * 9.75);
  --text-42: calc(var(--text-base) * 10.5);
  --text-6xl: calc(var(--text-base) * 12);
  --text-7xl: calc(var(--text-base) * 13);
  --text-8xl: calc(var(--text-base) * 14);
  --text-9xl: calc(var(--text-base) * 15);
  --text-10xl: 100px;
  --text-abnormal: 128px;

  // -----------------  Font weight  ------------------------//

  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-black: 900;

  // -----------------  Line height  ------------------------//
  --leading-3: 0.75rem; /* 12px */
  --leading-4: 1rem; /* 16px */
  --leading-5: 1.25rem; /* 20px */
  --leading-6: 1.5rem; /* 24px */
  --leading-7: 1.75rem; /* 28px */
  --leading-8: 2rem; /* 32px */
  --leading-9: 2.25rem; /* 36px */
  --leading-10: 2.5rem; /* 40px */
  --leading-12: 3rem; /* 48px */
  --leading-16: 4rem; /* 64px */
  --leading-17: 4.25rem; /* 68px */
  --leading-18: 4.5rem; /* 72px */
  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-snug: 1.375;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  --leading-loose: 2;

  // -----------------  Colors  ------------------------//
  // --color-app: #171717;
  --color-app: #0B1524;
  --color-gray: #69738e;
  --color-gray1: #707070;
  --color-gray2:#B1B8BF;
  --color-blue: #374666;
  --color-black: #000000;
  --color-text: #474747;
  --color-success:#38CF8A;
  --color-primary: #F6F9FC;
  --color-ternary: #CBA561;
  --color-heading: #222222;
  --color-lightestblue: #8A95AE;
  --color-lightblue: #374666;
  --color-skyblue: #F4F5FF;
  --color-lightgreen:#009B38;
  --color-darkred:#4A1B3D;
  --color-tableHeading: rgba(8, 7, 7, 0.6);
  --color-gray1:#8B8B8B;
  --color-fadedblack:#3C3D40;
  --color-black1:#201B21;
  
  --color-green: #087C14;
  --color-blue1: #0A1FB1;
  --color-lightgreen1: #D1FFF1;
  --color-lightblue1: #E8FDFF;
  --color-golden: #B1630A;
  --color-lightpink: #FFEBE8;
  --color-lightyellow: #FFFCE8;
  --color-gray10 :#F0F2F4;
  --color-yellow1:#E1AF26;
  --color-red: rgb(244 25 25);
  
  

  // -----------------  Sizes  ------------------------//

  --size-base: 0.25rem;
  --size-0: 0;
  --size-1: var(--size-base);
  --size-2: calc(2 * var(--size-base));
  --size-3: calc(3 * var(--size-base));
  --size-4: calc(4 * var(--size-base));
  --size-5: calc(5 * var(--size-base));
  --size-6: calc(6 * var(--size-base));
  --size-7: calc(7 * var(--size-base));
  --size-8: calc(8 * var(--size-base));
  --size-9: calc(9 * var(--size-base));
  --size-10: calc(10 * var(--size-base));
  --size-12: calc(12 * var(--size-base));
  --size-14: calc(14 * var(--size-base));
  --size-16: calc(16 * var(--size-base));
  --size-18: calc(18 * var(--size-base));
  --size-20: calc(20 * var(--size-base));

  // -----------------  Z-index Values  ------------------------//

  --z-1: 1;
  --z-2: 2;
  --z-4: 4;
  --z-6: 6;
  --z-8: 8;
  --z-10: 10;
  --z-20: 20;
  --z-30: 30;
  --z-40: 40;
  --z-50: 50;
  --z-60: 60;
}
