#{$app}- {
  &table {
    min-height: 40vh;
    &__ {
      &container {
        width: 100%;
        @include respond-below(lg) {
          width: fit-content;
        }
    }
      &heading {
        &-clubs {
          background: #f4f5ff;
          grid-template-columns:
            minmax(150px, 1fr)
            minmax(120px, 1fr)
            minmax(120px, 1fr)
            minmax(100px, 1fr)
            minmax(50px, 1fr);
        }
        &-adsManager {
          background: #f4f5ff;
          grid-template-columns:
            minmax(150px, 1fr)
            minmax(120px, 1fr)
            minmax(150px, 1fr)
            minmax(100px, 1fr)
            minmax(220px, 1fr);
        }
        &-events {
          background: #f4f5ff;
          grid-template-columns: minmax(250px, 1fr) minmax(100px, 1fr) minmax(
              80px,
              auto
            ) minmax(250px, 1fr);
            &Entry {
              background: #f4f5ff;
              grid-template-columns:
                minmax(200px, 1fr)
                minmax(120px, 1fr)
                minmax(80px,auto)
                minmax(150px, 1fr)
                minmax(220px, 1fr);
            }
        }
        background: #f4f5ff;
        grid-template-columns: minmax(250px, 1fr) minmax(100px, 1fr) minmax(
            100px,
            1fr
          ) minmax(100px, 1fr) minmax(220px, 1fr);
        &- {
          &name {
            color: var(--color-tableHeading);
            opacity: 0.66;
          }
        }
        &Payment {
          grid-template-columns: minmax(160px, 1fr) minmax(100px, 1fr) minmax(
              100px,
              1fr
            ) minmax(100px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr);
        }
      }
      &body {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        &- {
          &clubs {
            grid-template-columns:
              minmax(150px, 1fr)
              minmax(120px, 1fr)
              minmax(120px, 1fr)
              minmax(100px, 1fr)
              minmax(50px, 1fr);
              &:last-of-type {
                border-bottom: 0 !important;
              }
          }
          &adsManager {
            grid-template-columns: minmax(150px, 1fr) minmax(120px, 1fr) minmax(
                150px,
                1fr
              ) minmax(100px, 1fr) minmax(220px, 1fr);
              &:last-of-type {
                border-bottom: 0 !important;
              }
          }
          &event {
            grid-template-columns: minmax(250px, 1fr) minmax(100px, 1fr) minmax(
                80px,
                auto
              ) minmax(250px, 1fr);
            &:last-of-type {
              border-bottom: 0 !important;
            }
            &Entry {
              grid-template-columns:
                minmax(200px, 1fr)
                minmax(120px, 1fr)
                minmax(80px,auto)
                minmax(150px, 1fr)
                minmax(220px, 1fr);
                &:last-of-type {
                  border-bottom: 0 !important;
                }
            }
          }
          &item {
            grid-template-columns: minmax(250px, 1fr) minmax(100px, 1fr) minmax(
                100px,
                1fr
              ) minmax(100px, 1fr) minmax(220px, 1fr);
            &:last-of-type {
              border-bottom: 0 !important;
            }
            &Payment {
              grid-template-columns: minmax(160px, 1fr) minmax(100px, 1fr) minmax(
                  100px,
                  1fr
                ) minmax(100px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr);
            }
          }
        }
      }
      &status {
        min-width: 100px;
        // @include respond-below(lg) {
        //   width: 75px;
        // }
      }
      &event {
        &Wrapper {
          width: 75px;
          height: 75px;
        }
        &Img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  &sort {
    &__ {
      &lists {
        top: calc(100% + 5px);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
        width: max-content;
        max-width:150px;
      }
    }
  }
}

.list-visible {
  visibility: visible;
  opacity: 1;
}

.blinking
{
  color:  red;
}
