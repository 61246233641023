#{$app}-{
    &business{
        &__{
            &banner{
                background-image:url("../../../public/assets/images/banner-image.jpg");
                height:60vh;
                background-size:cover;
                &-{
                    &wrapper{
                        margin:auto;
                        width:80%;
                    }
                    &logo{
                        height:50px;
                        width:120px;
                    }
                }
            }
            &card{
                &-{
                    &wrapper{
                        background-color: #F4F4F4;
                    }
                }
                width:60%;
                // margin-top:-70px !important;
                border-radius:10px;
                background-color:#ffffff;
            }
            &whyPartner{
                &-{
                    &cards{
                        background-color: #ffffff;
                        border-radius:5px;
                        box-shadow: rgba(28, 28, 28, 0.1) 0px 4px 16px;
                    }
                }
            }
            &steps{
                background-image:url("../../../public/assets/images/how-it-works-background.jpg");
                background-repeat:no-repeat;
                background-size:cover;
                &-{
                    &cardImage{
                        height:150px;
                    }
                    &cardDescription{
                        height:150px;
                    }
                    &cards{
                        background-color: #ffffff;
                        box-shadow: rgba(28, 28, 28, 0.1) 0px 4px 16px;
                        border-radius:5px;
                        height:380px;
                        width:275px;
                        // &-{
                        //     &icon{
                        //         border-radius:50%;
                        //         width:120px;
                        //         height:120px;
                        //         &--{
                        //             &warning{
                        //                 background-color: #fffcf1;
                        //             }
                        //             &danger{
                        //                 background-color:#fff5f6;
                        //             }
                        //             &brown{
                        //                 background-color: #fff9f1;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }
            }
            &start{
                background-image:url("../../../public/assets/images/start-your-journey-with-party-one.png");
                background-repeat:no-repeat;
                background-size:cover;
                border-radius:10px;
            }
            &addRestaurant{
                background-image:url("../../../public/assets/images/contact-form-background.jpg");
                background-repeat:no-repeat;
                background-size:cover;
            }
            &faq{
                &-{
                    &accordion{
                        border:1px solid rgb(244, 244, 244) !important;
                        border-radius:5px !important;
                    }
                }
            }
            &location{
                padding:13px 15px 15px 24px;
                border:none;
                border-radius:5px 0px 0px 5px;
                &-{
                    &icon{
                        margin-left:-20px;
                        display:flex;
                        align-items:center;
                        color:#B1B8BF;
                        &--{
                            &new{
                                margin-right:-20px !important;
                                margin-left:0px;
                                z-index:2;
                            }
                            &search{
                                margin-left:-30px !important;
                            }
                        }
                    }
                }
                &::placeholder{
                    font-size:14px;
                    color:#B1B8BF;
                    font-family:var(--ff-montserrat);
                    font-weight:600;
                }
                &:focus{
                    outline-color: var(--color-ternary) !important;
                }
                &--{
                    &searchRestaurant{
                        border-radius:0px 5px 5px 0px !important;
                    }
                }
            }
        }
    }
}


.accordion-button:not(.collapsed){
    background-color: transparent !important;
    box-shadow: none !important;
    color:#DBA611 !important;
}

.accordion-button:focus{
    box-shadow: none !important;
}

.swiper-button-prev{
    right: var(--swiper-navigation-sides-offset,65px);
    top: var(--swiper-navigation-top-offset,90%);
    left: auto;
    z-index:50;
    &::after{
        font-size:40px !important;
    }
}

.swiper-button-next{
    right: var(--swiper-navigation-sides-offset,10px);
    top: var(--swiper-navigation-top-offset,90%);
    left: auto;
    z-index:50;
    &::after{
        font-size:40px !important;
    }
}

.swiper-wrapper{
    padding-bottom:100px;
}

.PhoneInputInput{
    display: block;
    width: 100%;
    border :1px solid #D2D6DA;
    border-radius: 5px; 
    padding: 10px;
    &:focus{
       outline-color: var(--color-ternary) !important;
       border: 0;
    }
}

.PhoneInput{
    display:flex;
}

.css-13cymwt-control{
    display: block;
    width: 100%;
    border :1px solid #D2D6DA;
    border-radius: 5px; 
    padding: 5px;
    font-family:var(--body-font) !important;
    &:focus{
//   border: 1px solid var(--color-ternary) !important;
    outline-color: var(--color-ternary) !important;
    border: 0;
    }
    &:placeholder{
        font-family:var(--body-font) !important;
    }
}

.css-qbdosj-Input{
    font-family:var(--body-font) !important;
}

@media (max-width:768px){
    .po-business__card{
        width:90% !important;
    }
}

@media (max-width:576px){
    .po-business__banner{
        background-image:url("../../../public/assets/images/banner-img-mobile.jpg");
    }
}