#{$app}- {
    &paginate {
        li {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid #E4E7EB;
            a {
                font-size: 14px;
                color: var(--color-gray1);
                width: 100%;
                text-align: center;
            }
        }
        li.selected {
            background-color: var(--color-app);
            a {
                color: var(--color-primary);
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}