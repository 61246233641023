#{$app}- {
    &primary__ {
        &radioInput {
            appearance: none;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 16px;
                height: 16px;
                background-color: rgba(23, 21, 21, 0.31);
                outline: 5px solid #D5D5D5;
                border-radius: 100%;
                transform: translate(0%, 25%);
            }
            &:checked {
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 16px;
                    height: 16px;
                    background-color: rgba($color: #232121, $alpha: 1);
                    border-radius: 100%;
                }
            }
        }
    }
}

