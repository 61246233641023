#{$app}-{
  &successButton{
    background-color: var(--color-lightgreen);
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
  }
  &deleteButton{
    padding: 10px 20px;
    color: var(--color-app);
    border-radius: 8px;
    border: 1px solid var(--color-app);
  }
  &OutlinedButton{
    padding: 10px 20px;
    color: var(--color-app);
    border-radius: 8px;
    border: 2px solid var(--color-app);
  }
}


// ripple effect 

.ripple-container {
  width: 30px;
  height: 30px;
  position: relative;
  margin-left: -10px;
}

.ripple-circle {
  width: 100%;
  height: 100%;
  // background-color: rgb(255, 174, 31);
  position: absolute;
  border-radius: 50%;
  animation: ripple-animation 1.5s infinite ease-out;
}

.ripple-circle1 {
  animation-delay: 0s;
}

.ripple-circle2 {
  animation-delay: 500ms;
}

.ripple-circle3 {
  animation-delay: 1s;
}

@keyframes ripple-animation {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}