// colors

.color {
  &- {
    &burgandi{
      color: #5b1f6e;
    }
    &app {
      color: var(--color-app);
    }
    &gray {
      color: var(--color-gray);
    }
    &gray2 {
      color: var(--color-gray2);
    }
    &blue {
      // color: var(--color-blue);
      color:#374666;
    }
    &skyBlue {
      color: var(--color-skyblue);
    }
    &black {
      color: var(--color-black);
    }
    &text {
      color: var(--color-text);
    }
    &success {
      color: var(--color-success);
    }
    &heading {
      color: var(--color-heading);
    }
    &lightestblue {
      color: var(--color-lightestblue);
    }
    &lightblue {
      color: var(--color-lightblue);
    }
    &lightgreen {
      color: var(--color-lightgreen);
    }
    &darkred {
      color: var(--color-darkred);
    }
    &gray1 {
      color: var(--color-gray1);
    }
    &ternary {
      color: var(--color-ternary);
    }
    &fadedblack {
      color: var(--color-fadedblack);
    }
    &green {
      color: var(--color-green);
    }
    &blue1 {
      color: var(--color-blue1);
    }
    &golden {
      color: var(--color-golden);
    }
    &ternary {
      color: var(--color-ternary);
    }
    &red {
      color: red;
    }
    &black1{
      color: var(--color-black1);
    }
    &black2{
      color: var(--color-tableHeading);
    }
    &yellow1{
      color: var(--color-yellow1);
    }
  }
}
.c-golden {
  color: #F7CE73!important;
}
.font {
  &- {
    &body {
      font-family: var(--body-font);
    }
  }
}
@include TextSizes;
@include Spacings;
@include Opacity;
@include fontWeights;
@include LineHeights;
@include Width;

ul,
li {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
  // color: ;
}
#{$app}- {
  &d {
    &__ {
      &none {
        display: none;
      }
    }
  }
  &rotate {
    &-- {
      &180 {
        transform: rotate(180deg);
      }
    }
  }
  &word-break {
    word-break: break-all;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default{
  cursor: default;
}
.opacity-06{
  opacity: 0.5;
}
.hover-ternary {
  &:hover {
    color: var(--color-ternary) !important;
  }
}
.background {
  &- {
    &gradient{
      background: transparent linear-gradient(290deg, #DBA611 0%, #F7CE73 100%) 0% 0% no-repeat padding-box;
    }
    &lightgreen{
      background-color: var(--color-lightgreen);;
    }
    &lightpink {
      background-color: var(--color-lightpink);
    }
    &lightyellow {
      background-color: var(--color-lightyellow);
    }
    &lightgreen1 {
      background-color: var(--color-lightgreen1);
    }
    &lightblue1 {
      background-color: var(--color-lightblue1);
    }
    &skyBlue {
      background-color: var(--color-skyblue);
    }
    &appColor {
      background-color: var(--color-app);
    }
    &ternary {
      background-color: var(--color-ternary);
    }
    &gray10{
      background-color: var(--color-gray10);
    }
    &position {
      &- {
        &left {
          &- {
            &top {
              background-position: left top;
            }
            &center {
              background-position: left center;
            }
            &bottom {
              background-position: left bottom;
            }
          }
        }
        &right {
          &- {
            &top {
              background-position: right top;
            }
            &center {
              background-position: right center;
            }
            &bottom {
              background-position: right bottom;
            }
          }
        }
        &center {
          &- {
            &top {
              background-position: center top;
            }
            &center {
              background-position: center center;
            }
            &bottom {
              background-position: center bottom;
            }
          }
        }
      }
    }
    &size {
      &- {
        &cover {
          background-size: cover;
        }
        &contain {
          background-size: contain;
        }
        &auto {
          background-size: auto;
        }
      }
    }
    &repeat {
      &- {
        &norepeat {
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.zIndex__1 {
  z-index: var(--z-1);
}
.zIndex__10{
  z-index: 10;
}
.counter-control {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.po-w-90 {
  width: 90%;
}
.po-w-10 {
  width: 10%;
}
.box-shadow-type-a {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#{$app}- {
  &state {
    position: fixed;
    top: 20px;
    right: 20px;
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    &__ {
      &success {
        background: rgba(8, 124, 20, 0.3);
        border: 1px solid rgba(8, 124, 20, 0.3);
      }
      &loading {
        background: rgba(237, 215, 89, 0.3);
        border: 1px solid rgba(237, 215, 89, 0.3);
      }
    }
  }
  &objectFit {
    &--cover {
      object-fit: cover;
    }
    &--contain {
      object-fit: contain;
    }
  }
  &objectPosition {
    object-position: center;
  }
  &customCursor {
    &-- {
      &transparent {
        caret-color: transparent;
      }
    }
  }
  &customer {
    background-color: #444e86;
  }
  &admin {
    background-color: #dd5182;
  }
  &manager {
    background-color: #ff6e54;
  }
  &influencer {
    background-color: #955196;
  }
  &concierge {
    background-color: #009b38;
  }

}

.radius-105{
  border-radius: 105px;
}
.po-border{
  border-width: 2px;
  border-style: solid;
  &-{
    &black{
      border-color: #363636;
    }
    &yellow1{
      border-color: var(--color-yellow1);
    }
  }
}
.gold-bullets{
  position: relative;
  &::after{
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--color-yellow1);
    position: absolute;
    left: -15px;
    top: 6px;
  }
}
.all-unset{
  all: unset;
}

.po-toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.po-toggle-switch input[type="checkbox"] {
  display: none;
}
.po-toggle-switch .po-switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.po-toggle-switch .po-switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.po-toggle-switch input[type="checkbox"]:checked + .po-switch::before {
  transform: translateX(25px);
  background-color: #fff;
}
.po-toggle-switch input[type="checkbox"]:checked + .po-switch {
  background-color: #009b38;
}

.po-bg-danger{
  background-color:#e54d5c ;
}
.po-bg-warning{
  background-color:#e8c517;
}
.po-bg-success{
  background-color:#65c15b ;
}
.react-date-picker__wrapper {
  border: 0;
}
.react-date-picker__inputGroup {
  height: 21px;
}
.react-date-picker__button {
  padding: 1px 6px;
}

.po-table__wrap th{
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
// table
.tableFixHead {
  overflow-y: auto;
  height: 560px;
  // height: calc(100vh - 420px);
  // @media(min-width:2000px){
  //   height: 55vh;
  // }
  &-tabs {
    height: 500px;
  }
}
.tableFixHead thead tr {
  position: sticky;
  top: 0;
  z-index: 100;
  left: 0;
  right: 0;
}
.tableFixHead table {
  border-collapse: collapse;
  width: 100%;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.border-dashed{
  border-style: dashed!important;
 }
 .btn-close{
  color: var(--color-app);
 }
 .box-shadow-input
 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }
 .custom-toaster .toast-header,.toast-body {
  background-color: #0E1620;
  border: 0;
  border-radius: 0;
  color: #fff;
 }
 .custom-toast-container {
  scrollbar-width: none;
 }
.z-10{
  z-index: 10;
}