#{$app}-{
    &login{
        &__{
            &wrapper{
                height:100vh;
                // background-image: url("../../../public/assets/images/login-background.svg");
                font-family: var(--ff-montserrat);
                background-repeat: no-repeat;
                background-size: cover;
                background-color:#090F17;
            }
            &button{
                background: linear-gradient(282deg, #DBA611, #F7CE73);
                color:#201B21;
                border-radius:105px;
                border:none;
            }
        }
    }
    
}