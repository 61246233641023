#{$app}-{
    &notification {
        &__ {
            &icon-bg {
                width: 38px;
                height: 38px;
                background-color: #090F17;
                box-shadow: 0px 10px 20px #00000014;
                @media screen and (min-width: 1400px) {
                    width: 42px;
                    height: 42px;
                }
            }
        }
    }
}

.on-hover:hover{
    background-color: #929292;
}

