#{$app}- {
    &scroll {
        &__ {
            &outerX {
               overflow-x: scroll;
               scrollbar-width: thin;
                &::-webkit-scrollbar {
                    height: 5px;
                }
            }
        }
    }
}