#{$app}- {
    &secondary {
        &__ {
            &inputFile {
                border :1px solid #D2D6DA;
                border-radius: 5px;
                padding: 7px 10px;
                &- {
                    &inner {
                        height: 36px;
                        left: 6px;
                        // z-index: -1;
                    }
                    &choose {
                        background: #DFDFDF;
                        border-radius: 6px;
                        color: #3E3E3E;
                        width: calc(100% - 12px);
                    }
                }
            }
        }
    }
    &input {
        &__ {
            &dropdownIcon {
                bottom: 25%;
                right: 12px;
                margin: auto;
            }
        }
    }
}
.po-toggle-button {
    position: relative;
    width: 60px;
    height: 30px;
    border: none;
    border-radius: 15px;
    background-color: #dcdcdc;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .po-toggle-button.active {
    background-color: #4caf50;
  }

  .po-toggle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    transition: opacity 0.3s ease;
  }

  .po-toggle-button.active .toggle-text {
    opacity: 1;
  }

