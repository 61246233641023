#{$app}- {
    &header {
        &__ {
            &search {
                &Field {
                    border-radius: 8px;
                }
                &Input {
                    outline: none;
                }
            }
        }
    }
}